if ( $(".content .avancement-devoirs").length ) {
	var afficherDisciplinesDeColis = function (current) {
		var tableParente = current.closest('.table-avancement-devoirs');

		var classeColis = current.attr('class').split(" ")[0];
		var classeTable = tableParente.attr('class').split(" ")[0];

		current.addClass('selected');
		$('.' + classeTable + ' .select-js-colis').each(function(){
			if(!$(this).hasClass(classeColis)) {
				$(this).removeClass('selected');
			}
		});

		$('.' + classeTable + ' .discipline').each(function(){

			if($(this).hasClass(classeColis)) {
				$(this).removeClass('hidden');
			}
			else {
				$(this).addClass('hidden');
			}

			if($(this).hasClass('more')) {
				var bouton = $(this).find('.bouton-devoirs').first();
				var dis = bouton.data('discipline');
				dis = dis.replace( /(:|\.|\[|\]|\')/g, "\\$1" );
				$("[class*='devoir-"+dis+"']").toggleClass('hidden');

				$(this).closest('.discipline').addClass("less");
				$(this).closest('.discipline').removeClass("more");
				bouton.find('a').html('<i class="fa fa-plus white" aria-hidden="true"></i>');
			}
		});
	}

	$('.table-avancement-devoirs .discipline').each(function(){
		$(this).addClass('hidden');
	});


	$('body').on("click", '.discipline.full', function() {
		bouton = $(this).find('.bouton-devoirs');

		var dis = bouton.data('discipline');
		dis = dis.replace( /(:|\.|\[|\]|\')/g, "\\$1" );
		$("[class*='devoir-"+dis+"']").toggleClass('hidden');
		var plusMoins = bouton.children().children();
		if ($(this).hasClass("less")) {
			$(this).addClass("more");
			$(this).removeClass("less");
			$(this).find('a').html('<i class="fa fa-minus white" aria-hidden="true"></i>');
			plusMoins.removeClass("fa-plus");
			plusMoins.addClass("fa-minus");

		} else if ($(this).hasClass("more")) {
			$(this).addClass("less");
			$(this).removeClass("more");
			$(this).find('a').html('<i class="fa fa-plus white" aria-hidden="true"></i>');
			plusMoins.removeClass("fa-minus");
			plusMoins.addClass("fa-plus");
		}
	});

	if ($(".table-avancement-devoirs .select-js-colis").length) {
		afficherDisciplinesDeColis($('.table-avancement-devoirs .select-js-colis:first'));
	}

	$('body').on('click', '.table-avancement-devoirs .select-js-colis', function(){
		if($(this).hasClass('selected'))
		{
			$('.discipline').each(function(){
				$(this).addClass('hidden');
				$(this).closest('.discipline').addClass("less");
				$(this).closest('.discipline').removeClass("more");
				$(this).find('a').html('<i class="fa fa-plus white" aria-hidden="true"></i>');
				});
			$('.devoir').each(function(){
				$(this).addClass('hidden');
			});
			$(this).removeClass('selected');
		}
		else
		{
			afficherDisciplinesDeColis($(this));
		}
	});

	$('body').on('click', '.table-avancement-devoirs .nom-formation', function(){
		var tableParente = $(this).closest('.table-avancement-devoirs');
		var classe = tableParente.attr('class').split(" ")[0];

		$('.avancement-formation-body.' + classe).toggleClass('hidden');
		$('.noms-colis.' + classe).toggleClass('hidden');

		if($(this).hasClass('more'))
		{
			$(this).removeClass('more');
		}
		else
		{
			$(this).addClass('more');
		}
	});
}
