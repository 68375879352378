var Interface = {
    typeAffectation: "",
    unusedCible: [],

    init: function() {
        this.dom = Object.create(Dom);
    },

    initAffectPar: function(){
        this.dom.selectAffectPar.select2({
            templateResult: function (data, container) {
                if (data.element) {
                    $(container).addClass($(data.element).attr("class"));
                }
                return data.text;
            }
        });
        this.dom.select2Css();
    },

    initImportFrom: function(){
        this.dom.selectImportFrom.select2({
            templateResult: function (data, container) {
                if (data.element) {
                    $(container).addClass($(data.element).attr("class"));
                }
                return data.text;
            }
        });
        this.dom.select2Css();
    },

    initListener: function(){
        var self = this;
        // etape 1
        this.dom.body.on('click', this.dom.classLockAffectPar,  function(event){
            self.lockAffectPar(event);
        });
        this.dom.body.on('click', this.dom.classUnLockAffectPar, function(event){
            self.unlockAffectPar(event);
        });

        // etape 2
        this.dom.body.on('click', this.dom.classLockFormation, function(event){
            self.lockFormation(event);
        });
        this.dom.body.on('click', this.dom.classUnLockFormation, function(event){
            self.unlockFormation(event);
        });
        this.dom.body.on('click', this.dom.classFormationSelection, function(event){
            self.selectionneFormation(event, $(this));
        });
        this.dom.body.on('click', this.dom.classCorrespondanceSelection, function(event){
            self.selectionneCorespondance(event, $(this));
        });
        this.dom.body.on('click', this.dom.classImportFrom, function(event) {
            self.importFrom(event);
        })

        // etape 3
        this.dom.body.on('click', this.dom.idAddNotListed, function(event){
            self.addNotListed(event);
        });
        this.dom.body.on('click', this.dom.idSaveAffectation, function(event){
            self.saveAffectation(event);
        });

        // success
        this.dom.body.on('click', this.dom.classOverlaySuccess, function(event){
            self.dom.overlaySuccess('hide');
        });
    },

    lockAffectPar: function(event){
        event.preventDefault();
        var data = this.dom.formAffectPar.serializeArray();
        data.push({ name : "etape1" , value: "etape1"});
        self = this;
        $.ajax({
            method: "POST",
            url: this.dom.formAffectPar.attr('action'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data
        })
        .done(function(data){
            self.showFormations(data);
            self.toggleUnlock('show', 'affectPar');
        });
    },

    toggleUnlock: function(type, etape){
        if(type == 'hide'){
            if(etape == 'affectPar'){
                $(this.dom.classUnLockAffectPar).addClass('hidden');
                $(this.dom.classLockAffectPar).prop('disabled', false);
                this.dom.selectAffectPar.prop('disabled', false);
            } else if(etape == 'formations'){
                $(this.dom.classUnLockFormation).addClass('hidden');
                $(this.dom.classLockFormation).prop('disabled', false);
                this.dom.formationsBox.removeClass('gm-formations-lock');
            }
        } else if(type == 'show'){
            if(etape == 'affectPar'){
                $(this.dom.classUnLockAffectPar).removeClass('hidden');
                $(this.dom.classLockAffectPar).prop('disabled', true);
                this.dom.selectAffectPar.prop('disabled', true);
            } else if(etape == 'formations'){
                $(this.dom.classUnLockFormation).removeClass('hidden');
                $(this.dom.classLockFormation).prop('disabled', true);
                this.dom.formationsBox.addClass('gm-formations-lock');
            }
        }
    },

    showFormations: function(data){
        var formations = data.collectionFormation;
        for(var i = 0; i < formations.length; i++){
            this.dom.formations.append(
                this.dom.renderFormationItem(formations[i])
            );
        }
        this.dom.formationsBox.removeClass('hidden');
    },


    unlockAffectPar: function(event){
        event.preventDefault;
        this.resetFormations();
        this.resetCorrespondances();
        self.toggleUnlock('hide', 'affectPar');
        self.toggleUnlock('hide', 'formations');
    },

    importFrom: function(event) {
        var savedValue = this.dom.selectAffectPar.val();
        this.dom.selectAffectPar.val(this.dom.selectImportFrom.val());
        this.resetCorrespondances();
        this.lockFormation(event)
        this.dom.selectAffectPar.val(savedValue);
    },

    lockFormation: function(event){
        event.preventDefault;
        var affectPar = this.dom.selectAffectPar.val();
        var data = this.dom.formFormations.serializeArray();
        data.push({ name : "affectationPar" , value: affectPar});
        data.push({ name : "etape2" , value: "etape2"});
        var self = this;
        $.ajax({
            method: "POST",
            url: this.dom.formFormations.attr('action'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data
        })
        .done(function(data){
            self.showCorrespondance(data);
            self.toggleUnlock('show', 'formations');
        })
        .fail(function(jqXHR) {
            alert(jqXHR.responseJSON.error);
        });

    },

    showCorrespondance: function(data){
        var formations = this.getSelectedFormations();
        this.dom.correpondances.html( this.dom.renderTableCorrespondance(data.itemsAffectes, formations).html() );
        this.dom.correpondancesBox.removeClass('hidden');
        this.manageUnusedCible(data.itemsNonAffectes);
    },

    getSelectedFormations: function(){
        var formations = [];
        $(this.dom.formationsChecked).each(function ()
        {
            formations.push($(this).val());
        });
        return formations;
    },

    manageUnusedCible: function(unused){
        this.unusedCible = unused;
        var arrayToSelect2 = [];
        for(var obj in unused){
           arrayToSelect2.push({
                id: obj,
                text: unused[obj].lib,
                isInterne: (unused[obj].isInterne ? true : false)
           });
        }
        if(this.dom.affectNotListed.data('select2') != undefined){
            this.dom.affectNotListed.select2('destroy');
            this.dom.affectNotListed.html('');
        }
        var me = this;
        this.dom.affectNotListed.select2({
            data: arrayToSelect2,
            templateResult: function (data, container) {
                if (data.isInterne) {
                    $(container).addClass(me.dom.classBackgroundIsInterne);
                }
                return data.text;
            }
        });
        this.dom.select2Css();
    },

    unlockFormation: function(event){
        event.preventDefault;
        this.resetCorrespondances();
        self.toggleUnlock('hide', 'formations');
    },

    resetFormations: function(){
        this.dom.formationsBox.addClass('hidden');
        this.dom.formations.html('');

    },

    resetCorrespondances: function(){
        this.dom.correpondancesBox.addClass('hidden');
        this.dom.correpondances.html('');
        this.unusedCible = [];
    },

    addNotListed: function(event){
        event.preventDefault;
        var selected = this.dom.affectNotListed.val();
        var item = this.unusedCible[selected];
        this.dom.affectNotListed.select2('val', '');
        this.dom.detach(selected);
        this.dom.correpondances.append( this.dom.renderCorrespondanceLine(item, this.getSelectedFormations()) );
    },

    saveAffectation: function(event){
        event.preventDefault;
        var affectPar = this.dom.selectAffectPar.val();
        var data = this.dom.formCorrespondance.serializeArray();
        data.push({ name : "affectationPar" , value: affectPar});
        data.push({ name : "etape3" , value: "etape3"});
        $(this.dom.formationsChecked).each(function() {
            data.push({ name : "formations[]" , value: $(this).val()});
        });
        var self = this;
        $.ajax({
            method: "POST",
            url: this.dom.formCorrespondance.attr('action'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data
        })
        .done(function(data){
            self.dom.overlaySuccess('show', data.message);
            self.unlockFormation(event);
        })
        .fail(function(jqXHR) {
            alert(jqXHR.responseJSON.error);
        });
    },

    selectionneFormation: function(event, node){
        event.preventDefault;
        var checked = false;
        if(node.hasClass('select')){
            checked = true;
        } else if (node.hasClass('unselect')){
            checked = false;
        }
        $(this.dom.formationsInput).each(function(){
            $(this).prop('checked', checked);
        });
    },

    selectionneCorespondance: function(event, node){
        event.preventDefault;
        var checked = false;
        if(node.hasClass('select')){
            checked = true;
        } else if (node.hasClass('unselect')){
            checked = false;
        }

        $('.correspondance-checked').each(function(){
            $(this).prop('checked', checked);
        });
    }
};
