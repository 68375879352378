var isInitAjaxFileDownload = false;

function initAjaxFileDownload() {

  if (!isInitAjaxFileDownload) {
    $('.form-download-export').submit(function() {
      $('.alert-danger').hide().empty();
      lockUIForDownload($(this));
    });

    function lockUI(form) {
      form.find('.btn-start-download').button('loading');
    }

    function unLockUI(form) {
      form.find('.btn-start-download').button('reset');
    }

    function downloadStarted(form) {
      window.clearInterval(fileDownloadCheckTimer);
      Cookies.remove('fileDownloadToken'); //clears this cookie value
      unLockUI(form);
    }

    var fileDownloadCheckTimer;

    function lockUIForDownload(form) {
      var token = new Date().getTime().toString(); //use the current timestamp as the token value
          form.find('input[name="download-token"]').val(token);

      lockUI(form);

      fileDownloadCheckTimer = window.setInterval(function() {
        var cookieValue = Cookies.get('fileDownloadToken');
        if (cookieValue === token) {
          downloadStarted(form);
        }
      }, 500);
    }
  }
  isInitAjaxFileDownload = true;
}
