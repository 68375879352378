$(document).ready(function() {
	var btnCacherElement  = $('.btn-cacher-element');
	var btnMontrerElement  = $('.btn-montrer-element');

	btnCacherElement.click(function(e){
		var downloaded = $('.downloaded');
		downloaded.each(function(index){
			$(this).addClass('hidden');
		});
		btnMontrerElement.removeClass('hidden');
		btnCacherElement.addClass('hidden');
	});

	btnMontrerElement.click(function(e){
		var downloaded = $('.downloaded');
		downloaded.each(function(index){
			$(this).removeClass('hidden');
		});
		btnCacherElement.removeClass('hidden');
		btnMontrerElement.addClass('hidden');
	});

});


function toggleTableMaj($this) {
    var idColis = $this.attr('id');
    var idTable = "table-"+idColis;
    $this.find("button .fa").toggleClass("fa-angle-right").toggleClass("fa-angle-down");

    if($("#table-"+idColis+"_wrapper").length > 0) {
        // Si la datable est deja créée pour ce tableau : on le rend visible directement
        $("#table-"+idColis+"_wrapper, #table-"+idColis+"_wrapper .table-colis").toggle();
    }
    else {
        // Sinon on créé la datable, puis on rend visible ce tableau
        $('#'+idTable).toggle();
        initTableMaj(idTable);
    }

}

function initTableMaj(idTable) {
    // Si le tableau Maj existe
  if($('#'+idTable).length > 0){
        $.fn.dataTable.moment( 'DD/MM/YYYY');
        var table = $('#'+idTable).DataTable({
            "language": {
                url: 'https://cdn.datatables.net/plug-ins/1.10.15/i18n/French.json'
            },
            "columnDefs": [
                { "orderable": false, "targets": 2},
            ],
            "processing": true,
            "info": false,
            "order": [[ 1, "desc" ]]
        });

        // Mise a jour des fichiers cachées à chaque rafraichissement
        var btnMontrerElement  = $('.btn-montrer-element');
        var downloaded = $('.downloaded');
        $('.table-maj').on( 'draw.dt', function () {
            downloaded.each(function(index){
                if ( btnMontrerElement.hasClass('hidden') ){
                    $(this).removeClass('hidden');
                }
                else {
                    $(this).addClass('hidden');
                }
            });
        });

    }
}