$('body').on('click', '.fermerOuvrirLegendeEleve', function(e) {
    gestionLegendeDevoirs(false);
});

function gestionLegendeDevoirs(chargementPage) {
    var etatLegende = 'ferme';
    if (Cookies.get("noLegendeCorrection") != undefined) {
        etatLegende = Cookies.get("noLegendeCorrection");
    }
    if (!chargementPage) {
        if (etatLegende == 'ouvert') {
            etatLegende = 'ferme';
        } else {
            etatLegende = 'ouvert';
        }
        Cookies.set("noLegendeCorrection", etatLegende);
    }
    if (etatLegende == 'ferme') {
        $('.fermer-legende').addClass('hidden');
        $('.items-legende').addClass('hidden');
        $('.legende').addClass('no-legend');
        $('.no-item').removeClass('hidden');
    } else {
        $('.fermer-legende').removeClass('hidden');
        $('.items-legende').removeClass('hidden');
        $('.legende').removeClass('no-legend');
        $('.no-item').addClass('hidden');
    }
}
$('.submit-on-change').on('change', function() {
    $(this).closest('form').submit();
});
$('body').on('change', 'input.get-nom-fichier', function() {
    var nomFichier = $(this)[0].files[0].name;
    var infosCopie = $(this).siblings('.infos-copie');
    var documentInterdit = $(this).siblings('.document-interdit');
    var tableauNomFichier = nomFichier.split('.');
    var extension = tableauNomFichier[tableauNomFichier.length - 1];
    if (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'doc' || extension.toLowerCase() === 'docx' || extension.toLowerCase() === 'mp3') {
        if (infosCopie.hasClass('hidden')) {
            infosCopie.removeClass('hidden');
        }
        if (!documentInterdit.hasClass('hidden')) {
            documentInterdit.addClass('hidden');
        }
        infosCopie.find('.nom-fichier').html(nomFichier);
    } else {
        if (!infosCopie.hasClass('hidden')) {
            infosCopie.addClass('hidden');
        }
        if (documentInterdit.hasClass('hidden')) {
            documentInterdit.removeClass('hidden');
        }
        documentInterdit.find('.nom-fichier').html(nomFichier);
    }
});
$('body').on('click', '.bouton.input-file', function(e) {
    e.preventDefault();
    $(this).siblings('input[type="file"]').click();
});
$('body').on('click', '.submit-form', function() {
    $(this).siblings('input[type="submit"]').click();
});
//Quand un élément s'ouvre en collapse
$('.tableau-devoirs-phone').on('show.bs.collapse', function() {
    $('.tableau-devoirs-phone .in').collapse('hide');
});
$('.header-sujet-devoir').on('click', function() {
    if ($(this).hasClass('more')) {
        $(this).removeClass('more');
    } else {
        $('.header-sujet-devoir').removeClass('more');
        $(this).addClass('more');
    }
});
//Pour les professeurs, bloque l'écriture de la note si l'état n'est pas mis à "corrigé"
$('body').on('change', '.popin .etat .input-etat, .tableau-devoirs-professeur .input-etat', function() {
    toggleDivNote($(this));
});

function toggleDivNote(current) {
    if (current.hasClass('papier')) {
        divNote = current.siblings('.note');
    } else {
        divNote = $('.popin .note');
    }
    //Si l'état est "corrigé"" ou "triche"
    if (current.val() == 4 || current.val() == 8) {
        divNote.removeClass('hidden');
        divNote.children('.input-note').attr('required', 'required');
    } else {
        divNote.addClass('hidden');
        divNote.children('.input-note').removeAttr('required');
    }
    afficherInfosEtat(current.val());
    reaffecter(current.val());
}

function afficherInfosEtat(idEtat) {
    divInfosEtats = $('.popin .infos-etat');
    //Etat corrigé
    if (idEtat == 4) {
        divInfosEtats.addClass('hidden');
    }
    //Etat à refaire
    else if (idEtat == 3) {
        divInfosEtats.removeClass('hidden');
        divInfosEtats.find('.info-etat').addClass('hidden');
        divInfosEtats.find('.a-refaire').removeClass('hidden');
    }
    //Etat inexploitable
    else if (idEtat == 7) {
        divInfosEtats.removeClass('hidden');
        divInfosEtats.find('.info-etat').addClass('hidden');
        divInfosEtats.find('.inexploitable').removeClass('hidden');
    }
    //Etat triche
    else if (idEtat == 8) {
        divInfosEtats.removeClass('hidden');
        divInfosEtats.find('.info-etat').addClass('hidden');
        divInfosEtats.find('.triche').removeClass('hidden');
    }
    //Etat à réaffecter
    else if (idEtat === 'reaffecter') {
        divInfosEtats.removeClass('hidden');
        divInfosEtats.find('.info-etat').addClass('hidden');
        divInfosEtats.find('.a-reaffecter').removeClass('hidden');
    }
}
/**
 * Permet de modifier les informations affichées en fonction de si
 * l'état est mit à réaffecter ou non
 *
 * @param      {string}  idEtat  The identifier etat
 */
function reaffecter(idEtat) {
    divFichierCommentaire = $('.depot-correction-professeur .fichier-commentaire');
    divReaffecter = $('.depot-correction-professeur .reaffecter');
    //Etat à réaffecter
    if (idEtat === 'reaffecter') {
        $('.depot-correction-professeur .infos-copie input[name="update-devoir"]').val("reaffecter");
    } else {
        $('.depot-correction-professeur .infos-copie input[name="update-devoir"]').val("deposer-correction");
    }
}
var affectationAjax = {
    init: function() {
        $('.affecter-devoir').submit(function(event) {
            event.preventDefault();
            $('#alert-erreur').hide(100);
            var datas = $(this).serialize();
            var varThis = $(this);
            var boutonAffectation = $(this).find('.btn-affectation');
            var idDevoir = boutonAffectation.attr('id').substr(13);
            if ($('#select-professeur-' + idDevoir + '-button').length == 0) {
                $('#alert-erreur').show(100);
                $('#alert-erreur').text('Veuillez sélectionner un professeur !');
                return;
            }
            var textBouton = boutonAffectation.val();
            var spinner = '<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>';
            boutonAffectation.after(spinner);
            boutonAffectation.val('Affectation...').prop('disabled', true);
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: datas,
                success: function(retour) {
                    var retour = jQuery.parseJSON(retour);
                    if (retour.status === 'success') {
                        $('#copie-' + retour.value).hide(100);
                        $('#alert-erreur').hide(100);
                    } else {
                        $('#alert-erreur').show(100);
                        $('#alert-erreur').text(retour.value);
                        boutonAffectation.val(textBouton).prop('disabled', false);
                        varThis.find('.fa-spinner').remove();
                    }
                }
            });
        });
    }
};
var rafraichissementNbCopie = {
    init: function() {
        $(".evenement-select-professeur").on('click', function(e) {
            var self = $(this);
            var urlBase = window.location.origin;
            $.ajax({
                url: urlBase + "/moderateur/devoir/recupererProfesseur/" + self.siblings('select').data('sujet'),
            }).done(function(data) {
                self.siblings('select').html(data).selectmenu().selectmenu("refresh").selectmenu("open").selectmenu({
                    classes: {
                        "ui-selectmenu-button": "form-control",
                        "ui-selectmenu-open": "max-height-200",
                    }
                });
                /*Couleur de fond pour les prof internes*/
                var idUiMenu = $('.ui-selectmenu-open .ui-menu').attr('id');
                var idSelectProf = idUiMenu.replace(/-menu/i, "");
                var lesClasses = new Array();
                $('#' + idSelectProf + ' option').each(function() {
                    lesClasses.push($(this).attr('class'));
                });
                $('.ui-selectmenu-open .ui-menu li.ui-menu-item').each(function(idx) {
                    if (lesClasses[idx] != undefined) {
                        $(this).addClass(lesClasses[idx]);
                    }
                });
            });
        });
    }
};

$('.telechargement-copie').click(function(e) {
    var token = $("meta[name='csrf-token']").attr("content");
    var that = this;
    var dataObject = {
        '_method': 'PUT',
        '_token': token,
        'telecharger-copie': 'telecharger-copie'
    };
    $.fileDownload($(this).data('url'), {
        httpMethod: "POST",
        data: dataObject,
        prepareCallback:function(url) {
            $('.maj-ajax-loader-container').show();
        },
        successCallback: function(url) {
            location.reload();
        },
        failCallback: function(responseHtml, url, error) {
            $('<div class="alert alert-danger fade in">La ressource demandée n\'a pas été trouvée</div>').appendTo($(that).parent());
            $(that).remove();
            $('.maj-ajax-loader-container').hide();
        }
    });
});


$('.telechargement-enonce').click(function(e) {
    var token = $("meta[name='csrf-token']").attr("content");
    var that = this;
    var dataObject = {
        '_token': token,
        'telecharger-enonce': 'telecharger-enonce',
        'idSujetDevoir': $(this).data('sujetdevoir'),

    };
    $.fileDownload($(this).data('url'), {
        httpMethod: "POST",
        data: dataObject,
        prepareCallback:function(url) {
            $('.maj-ajax-loader-container').show();
        },
        successCallback: function(url) {
            location.reload();
        },
        failCallback: function(responseHtml, url, error) {
            $('<div class="alert alert-danger fade in">La ressource demandée n\'a pas été trouvée</div>').appendTo($(that).parent());
            $(that).remove();
            $('.maj-ajax-loader-container').hide();
        }
    });
});

