
setMaxInputMontantLibre();

// Event : clique sur une des checkbox pour payer une échéance
$('.checkbox-paie-echeance').on('change', function(e) {
    triggerEvent(function() {
        if(e.target.checked) {
            updatePreviousCheckboxes(e.target)
            updateCheckboxTotalEcheancier(e.target)
            updateCheckboxTotal()
        } else {
            updateNextCheckboxes(e.target)
            updateCheckboxTotal()
            updateCheckboxTotalEcheancier(e.target)
        }

        updateInputMontantLibreFromEcheancier(e.target)
    })
})

// Event : clique sur une des checkbox pour payer la totalité d'un échéancier
$('.checkbox-paie-total-echeancier').on('change', function(e) {
    triggerEvent(function () {
        if(e.target.checked) {
            setCheckboxes(getCheckboxesFromEcheancier(e.target.id), true)
        } else {
            setCheckboxes(getCheckboxesFromEcheancier(e.target.id), false)
        }

        updateCheckboxTotal()
        updateInputMontantLibreFromEcheancier(e.target)
    })
})

// Event : clique sur la checkbox pour payer la totalité
$('.checkbox-paie-total').on('change', function(e) {
    triggerEvent(function () {
        setCheckboxes($('.checkbox-paie-echeance, .checkbox-paie-total-echeancier'), e.target.checked)
        updateInputMontantLibres()
    })
})

// choix manuel d'un montant à payer pour chaque échéancier
$('.input-montant-libre').on('input', function(e) {
    triggerEvent(function () {
        if(e.target.value === '') {
            setCheckboxes(getCheckboxesFromEcheancier(e.target.id), false)
            finEventInput()
            return;
        }

        let montant = parseFloat(e.target.value);
        let max = parseFloat(e.target.max);

        // la valeur ne doit pas être supérieure au maximum
        if(montant > max) {
            e.target.value = montant = max;
        }

        // la valeur ne doit pas avoir plus de 2 chiffres après la virgule
        if(montant.countDecimals() > 2) {
            e.target.value = montant.toFixed(2);
        }

        finEventInput()
    })
})

function triggerEvent(callback) {
    callback()

    updatePaiementPartiel()
    updateButton(calculNewMontant())
}

function finEventInput() {
    checkCheckboxesTotalEcheancier()
    updateCheckboxTotal()
}

function updateButton(montant) {
    $('#btn-paiement')[0].disabled = !(montant > 0);
    $('#span-total-a-payer')[0].innerHTML = montant;
}


function calculNewMontant() {
    let montant = 0;
    $('.input-montant-libre').each(function (index, input) {
        if(!isNaN(parseFloat(input.value))) {
            montant += parseFloat(input.value)
        }
    })

    return montant.toFixed(2);
}

function setMaxInputMontantLibre() {
    $('.input-montant-libre').each(function (index, input) {
        input.max = getMontantTotalEcheancier(input.id);
    })
}

/*
    Update checkboxes
 */

function updateNextCheckboxes(currentCheckbox) {
    getCheckboxesFromEcheancier(currentCheckbox.id).each(function (index, checkbox) {
        if(getNumFromId(checkbox.id) > getNumFromId(currentCheckbox.id)) {
            checkbox.checked = false;
        }
    })
}

function updatePreviousCheckboxes(currentCheckbox) {
    getCheckboxesFromEcheancier(currentCheckbox.id).each(function (index, checkbox) {
        if(getNumFromId(checkbox.id) < getNumFromId(currentCheckbox.id)) {
            checkbox.checked = true;
        }
    })
}

function updatePaiementPartiel() {
    $('.checkbox-paie-echeance').each(function (index, checkbox) {
        checkbox.checked = isMontantInputAboveEcheance(checkbox);
    })

    // une fois que toutes les checkboxs ont bien été changées, on peut vérifier s'il y a un paiement partiel
    $('.checkbox-paie-echeance').each(function (index, checkbox) {
        if(isCheckboxPartial(checkbox)) {
            checkbox.classList.add('partial')
        } else {
            checkbox.classList.remove('partial')
        }
    })
}

function updateCheckboxTotalEcheancier(currentCheckbox) {
    /*
        Pour les checkbox total des échéances les conditions pour qu'elles soient checked sont les suivantes :
            - la checkbox total est checked
            - ou toutes les autres checkbox de l'échéancier sont checked
     */
    const className = getClassNameFromId(currentCheckbox.id)
    const checkboxTotalEcheancier = $('.'+className).find('.checkbox-paie-total-echeancier')[0]
    checkboxTotalEcheancier.checked =
        isCheckboxTotalChecked()
        || areAllCheckboxFromEcheancierChecked(checkboxTotalEcheancier)
}

function updateCheckboxTotal() {
    /*
        Pour la checkbox total les conditions pour qu'elle soit checked sont les suivantes :
            - toutes les autres checkbox sont checked
    */
    $('.checkbox-paie-total')[0].checked = areAllCheckboxesChecked();
}

/*
    Update input
 */
function updateInputMontantLibreFromEcheancier(currentCheckbox) {
    let input = getInputMontantLibreFromEcheancier(currentCheckbox.id)
    let montantEcheancier = 0;
    getCheckboxesFromEcheancier(currentCheckbox.id).each(function (index, checkbox) {
        if(checkbox.checked) {
            montantEcheancier += getMontantFromCheckbox(checkbox)
        }
    })

    input.value = parseFloat(montantEcheancier).toFixed(2);
}

function updateInputMontantLibres() {
    $('.input-montant-libre').each(function (index, input) {
        let montantEcheancier = 0;
        getCheckboxesFromEcheancier(input.id).each(function (index, checkbox) {
            if(checkbox.checked) {
                montantEcheancier += getMontantFromCheckbox(checkbox)
            }
        })

        input.value = parseFloat(montantEcheancier).toFixed(2);
    })
}

/*
    Conditions
 */

function areAllCheckboxesChecked() {
    return areCheckboxesChecked(
        $('.checkbox-paie-echeance, .checkbox-paie-total-echeancier')
    )
}

function areAllCheckboxFromEcheancierChecked(checkbox) {
    return areCheckboxesChecked(
        getCheckboxesFromEcheancier(checkbox.id)
    )
}

function isCheckboxTotalChecked() {
    return $('.checkbox-paie-total')[0].checked;
}

function isMontantInputAboveEcheance(checkbox) {
    let montantInput = getInputMontantLibreFromEcheancier(checkbox.id).value
    let sommeMontantEcheancePrecedente = 0

    for(let otherCheckbox of getCheckboxesFromEcheancier(checkbox.id).toArray()) {

        if(otherCheckbox.id === checkbox.id) {
            break;
        }
        sommeMontantEcheancePrecedente += getMontantFromCheckbox(otherCheckbox)
    }

    return parseFloat(montantInput) > sommeMontantEcheancePrecedente
}

/*
    Fonctions utiles
 */

// on récupère le montant qui est affiché dans la colonne à gauche de la checkbox
function getMontantFromCheckbox(checkbox) {
    let className = getClassNameFromId(checkbox.id)
    let num = getNumFromId(checkbox.id)
    let montant = $('#'+className+'-montantEcheance-'+num)[0].innerHTML
    // on enlève le €
    montant = montant.slice(0, montant.length-1)
    return parseFloat(montant);
}

function getMontantFromCheckboxes(checkboxes) {
    let montant = 0;
    checkboxes.each(function (index, checkbox) {
        if(checkbox.checked) {
            montant += getMontantFromCheckbox(checkbox)
        }
    })
    return parseFloat(montant.toFixed(2))
}

function areCheckboxesChecked(checkboxes) {
    for (let checkbox of checkboxes.toArray()) {
        if(!checkbox.checked) {
            return false;
        }
    }
    return true;
}

function getCheckboxesFromEcheancier(id) {
    const className = getClassNameFromId(id)
    return $('.'+className).find('.checkbox-paie-echeance')
}

function getInputMontantLibreFromEcheancier(id) {
    const className = getClassNameFromId(id)
    return $('.'+className).find('.input-montant-libre')[0]
}

function getMontantTotalEcheancier(id) {
    const className = getClassNameFromId(id)
    return parseFloat(
        $('#'+className+'-montantTotalEcheancier')[0].innerHTML
    )
}

function getClassNameFromId(id) {
    return id.split('-')[0]
}

function getNumFromId(id) {
    return parseInt(
        id.split('-')[id.split('-').length-1]
    )
}

function setCheckboxes(checkboxes, value) {
    checkboxes.each(function (index, checkbox) {
        checkbox.checked = value
    })
}

function isCheckboxPartial(checkbox) {
    let montantFromCheckbox = getMontantFromCheckboxes(
        getCheckboxesFromEcheancier(checkbox.id)
    )
    let montantInput = parseFloat(
        getInputMontantLibreFromEcheancier(checkbox.id).value
    )

    return montantFromCheckbox > montantInput && isLastCheckedCheckbox(checkbox)
}

function isLastCheckedCheckbox(checkbox) {
    // récupération de la checkbox suivant
    const className = getClassNameFromId(checkbox.id)
    const num = getNumFromId(checkbox.id)
    const nextNum = parseInt(num)+1
    const nextCheckbox = $('#'+className+'-echeance-'+nextNum)[0]

    if(!nextCheckbox) {
        return true;
    }

    return !nextCheckbox.checked;
}

function isMontantInputEqualMontantTotalEcheancier(checkbox) {
    let montantInput = getInputMontantLibreFromEcheancier(checkbox.id).value
    let montantTotal = getMontantTotalEcheancier(checkbox.id)

    return montantInput > 0 && parseFloat(montantInput) === montantTotal
}

function checkCheckboxesTotalEcheancier() {
    $('.checkbox-paie-total-echeancier').each(function (index, checkbox) {
        checkbox.checked = isMontantInputEqualMontantTotalEcheancier(checkbox)
    })
}

Number.prototype.countDecimals = function () {
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split('.')[1].length || 0;
}
