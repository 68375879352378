var Dom = {
    body: $('body'),
    selectAffectPar : $('select[name=affectationPar]'),
    selectImportFrom : $('select[name=importFrom]'),
    formationsBox: $('#affectPar-formation'),
    formations: $('#affectPar-formation .formations'),
    formationsInput: "input[name='formations[]']",
    formationsChecked: "input[name='formations[]']:checked",

    correpondancesBox: $('#affectPar-correspondance'),
    correpondances: $('#affectPar-correspondance table'),

    affectNotListed: $('#affect-not-listed'),


    formAffectPar: $('form#form-affectPar'),
    formFormations: $('form#form-formations'),
    formCorrespondance: $('form#form-correspondance'),

    classFormationSelection: '.formation-selectionner',

    classCorrespondanceSelection: '.correspondance-selectionner',

    classLockAffectPar: '.lock-affectPar',
    classUnLockAffectPar: '.unlock-affectPar',

    classLockFormation: '.lock-formation',
    classUnLockFormation: '.unlock-formation',

    classImportFrom: '.import-from',

    idSaveAffectation: '#save-affect',
    idAddNotListed: '#add-not-listed',

    classOverlaySuccess: '.overlay-success',

    classBackgroundIsInterne: 'bg-grey-light-1-no-imp',


    renderFormationItem: function(data){
        var div = $('<div/>');
        var label = $('<label/>');
        label.attr('for', 'formation-'+data.id);
        label.text(data.id+ ' - ' +data.text);

        var input = $('<input/>');
        input.attr('type', 'checkbox');
        input.attr('id', 'formation-'+data.id);
        input.attr('name', 'formations[]');
        input.val(data.id);

        div.append(input);
        div.append(label);
        return div;
    },

    renderTableCorrespondance: function(data, formations){
        var thead = $('<thead/>');
        thead.append($('<th/>'));

        for(var y = 0; y < formations.length; y++){
            thead.append($('<th/>').append(formations[y]));
        }
        var tbody = $('<tbody/>');

        for (var obj in data){
            tbody.append( this.renderCorrespondanceLine(data[obj], formations) );
        }

        var table = $('<table/>');
        table.append(thead);
        table.append(tbody);
        return table;
    },

    renderCorrespondanceLine: function(data, formations){
        var target = $('<td/>').append(data.lib);
        if (data.isInterne) {
            target.addClass('bg-grey-light-1-no-imp');
        }
        var line =  $('<tr/>').append(target);
        for(var i = 0; i < formations.length; i++) {
            line.append(this.renderCorrespondanceCell(data.forms, formations[i]));
        }
        return line;
    },

    renderCorrespondanceCell: function(listFormations, currentFormation){
        var cell = $('<td/>');
        if (listFormations[currentFormation] !== undefined) {
            var input = $('<input/>').attr('type', 'checkbox');
            input.attr('name', 'correspondance['+currentFormation+']['+listFormations[currentFormation]['cible']+']');
            var checked = false;
            if(listFormations[currentFormation]['checked'] == '1'){
                checked = true;
            }
            var id = currentFormation+'-'+listFormations[currentFormation]['cible'];
            input.attr('id', id );
            input.attr('class', 'correspondance-checked');
            input.attr('checked', checked);
            cell.append( input );
            var label = $('<label/>');
            label.text('');
            label.attr('for', id);
            cell.append( label );
        } else {
            cell.addClass('disabled');
        }

        return cell;
    },

    detach: function(selected){
        this.affectNotListed.find('option[value="'+selected+'"]').detach();
    },

    select2Css: function(){
        $('.select2').addClass('form-control');
    },


    overlaySuccess: function(action, msg){
        if (typeof(msg)=='undefined') {
            msg = "";
        }
        if(action == 'show'){
            this.body.prepend( this.genereOverlay(msg) );
        } else if(action == 'hide'){
            $(this.classOverlaySuccess).remove();
        }
    },

    genereOverlay: function(msg){
        var over = $('<div />');
        over.addClass('overlay-success');
        var content = $('<div />');
        content.addClass('overlay-content');
        content.html('<i class="fa fa-check"></i><p>'+msg+'</p><small>( Cliquer pour continuer )</small>');
        over.html(content);
        return over;
    }
};
