function initWysiwyg() {
    tinymce.init({
        selector: 'textarea.tinymce',  // change this value according to your HTML
        menubar: false,
        toolbar: 'undo redo |  fontsizeselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat',
        language: 'fr_FR',
        plugins: 'wordcount, lists, link, textcolor',
        fontsize_formats: '8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px',
        textcolor_map: [
            "000000", "Noir",
            "FFFFFF", "Blanc",
            "FF0000", "Rouge",
            "E4A000", "Orange",
            "FFFF00", "Jaune",
            "7EAF46", "Vert",
            "337AB7", "Bleu",
            ]
    });
}

$('#formulaire-connexion-utilisateur').submit( function(e){
	$('#bouton-connexion-utilisateur').attr('disabled', 'disabled');
});

$('body').on('click', '.btn-download-maj', function(e){
	$(this).closest('tr').addClass('downloaded');
});
$('body').on('click', '.btn-download-datatable', function(e){
	$(this).closest('tr').addClass('downloaded');
});

$('a.btn-click-to-unlock').each(function(e){
	$(this).attr('tabindex', 0);
	$(this).append('<span class="unlocked">Supprimer ?</span>');
});

$('body').on('click', '.btn-click-to-unlock .unlocked', function(e){
	$(this).closest('form').submit();
});

$('body').on('click','.no-event', function(e){
	e.preventDefault();
});

$('body').on('click', '[data-target=".popin"]', function(){
    var popinTitle = $(this).siblings('.popin-title').html();
    $('.popin .title').html(popinTitle);

    var popinContent = $(this).siblings('.popin-content').html();
    $('.popin .modal-body').html(popinContent);

    initWysiwyg();
});

$('button[class*=open-confirm-delete]').on('click', function(e) {
    e.preventDefault();
    var $form = $(this).closest('form');

    $('.popin .title').html($(this).parent().siblings('.popin-title').html());
    $('.popin .modal-body').html($(this).parent().siblings('.popin-content').html());

    $('.popin').modal({
        backdrop: 'static',
        keyboard: false
    })
    .one('click', 'button[class*=confirmed-delete]', function(e) {
        $form.trigger('submit');
    });
});



// $('body').on('click', 'a.btn-click-to-unlock:not(.unlocked)', function(e){
// 	e.preventDefault();
// 	$(this).attr('href',$(this).data('href'));
// 	$(this).addClass('unlocked');
// });

// $('body').on('click', ':not(.unlocked)', function(e){
// 	var thisClick;
// 	if($(this).closest('.btn-click-to-unlock').length > 0){
// 		$('.unlocked.btn-click-to-unlock').each(function(ev){
// 			$(this).removeClass('unlocked');
// 			$(this).attr('href', '#');
// 		});
// 		if($(this).hasClass('btn-click-to-unlock')){
// 			e.preventDefault();
// 			$(this).attr('href',$(this).data('href'));
// 			$(this).addClass('unlocked');
// 		}
// 	}
// });

// $('body').on('click', '.unlocked.btn-click-to-unlock', function(e){
// 	$(this).removeClass('unlocked');
// });

function beforePrint(){
    $('a').each(function(i, elem) {
        $(elem).data('link', $(elem).prop('href'));
        $(elem).removeAttr('href');
    });
}
function afterPrint() {
    $('a').each(function(i, elem) {
        $(elem).attr('href', $(elem).data('link'));
    });
}

if (window.matchMedia) {
    var mediaQueryList = window.matchMedia('print');
    mediaQueryList.addListener(function(mql) {
        if (mql.matches) {
            beforePrint();
        }
        else {
            afterPrint();
        }
    });
}

function slugify (str) {
    var map = {
        '' : ' ',
        '-' : '_',
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
    };

    str = str.toLowerCase();

    for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    };

    return str;
}

$('input:radio[name=optionCertif]').on('change', function(){
    $('span#choix-certification').text($(this).val());
});