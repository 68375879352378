$(document).ready(function() {

	$( "span.close" ).click(function() {
		var version = $('#version');


		// On retire la div sur la droite
		version.animate({
			"right":"-330px"
		}, 500);

		// et à la fin on la supprime complètement
		setInterval(function(){
			version.remove();
		}, 1000);


	});

	$("body").on('click', '#version', function(e) { // le script utilisé génère deux popins  -> Je supprime la première trouvé
		$('#popin-content').remove();
		$('#popin-title').remove();
	});
});
