titrePlateformeDevoirs = $('.tutosPlateformeDevoirs .titre');
liensVideosPlateformeDevoirs = $('.tutosPlateformeDevoirs .liensVideos');

actualitesSimples = $('.actualites-raccourcis .actualites-simples');
actualitesImportantes = $('.actualites-raccourcis .actualites-importantes');
boutonsRaccourcis = $('.actualites-raccourcis .boutons-raccourcis');
boutonRaccourcis = $('.actualites-raccourcis .bouton-raccourcis');

/*
* Met le carousel bootstrap en pause lors du clic sur une des flèches,
* sur un point ou sur le bouton "lire la suite"
*/
$('.carousel').on('click', '.carousel-control, .carousel-dot-control', function() {
	pauseCarousel($(this));
});

//Ne marche pas (???)
$('.carousel').on('click', '.lire-plus', function() {
	pauseCarousel($(this));
});

var pauseCarousel = function(current)
{
	slider = current.parents('.carousel');
	slider.carousel('pause');
}

//JS desktop
if($(window).width() > 992)
{
	//Initialisation des popup d'aide sur les boutons
	$('[data-toggle="tooltip"]').tooltip({container: 'body'});
}


//JS mobile
else
{
	limiteCaracteres = 130;
	$('.actualites .item p').each(function() {
		contenu = $(this).text();
		if(contenu.length > limiteCaracteres)
		{
			var partVisible = $('<span>' + contenu.substr(0, limiteCaracteres - 1) + '</span>');
			var dots = $('<span class="dots">...</span>');
			var partCachee = $('<span class="suite">' + contenu.substr(limiteCaracteres - 1) + '</span>');
			var lirePlus = $('<div class="lire-plus bouton-dual-color-light"><a href="#" class="noStyle" onclick="return false"><span>Lire la suite</span></a></div>');

			lirePlus.click(function() {
                $(this).prev().prev().remove(); // remove dots
                $(this).prev().show();
                $(this).remove(); // remove 'read more'
            });

            $(this).empty()
            	.append(partVisible)
                .append(dots)
                .append(partCachee)
                .append(lirePlus);
		}
	});

	titrePlateformeDevoirs.attr('data-toggle', 'collapse');
	titrePlateformeDevoirs.attr('data-target', '#liensVideosPlateformeDevoirs');

	liensVideosPlateformeDevoirs.addClass('collapse');
	liensVideosPlateformeDevoirs.attr('id', 'liensVideosPlateformeDevoirs');
	liensVideosPlateformeDevoirs.attr('aria-expanded', 'true');
	liensVideosPlateformeDevoirs.collapse('hide');
}


$('.modal').on('hidden.bs.modal', function(){
	$('.modal-body iframe.video').attr('src', '');
});


//Gestion de l'accordéon mobile
$('#mainMenuPhone').on('show.bs.collapse', function () {
    $('#mainMenuPhone .in').collapse('hide');
});

$('#mainMenuPhone .mainItem').on('click', function(){
	$('.mainItem').each(function(){
		$(this).removeClass('more');
	});


	$('.header-sujet-devoir').removeClass('more');
	if($(this).attr('aria-expanded') == 'true') {
		$(this).removeClass('more');
	} else {
		$(this).addClass('more');
	}

});