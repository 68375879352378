$(document).ready(function() {
    var datatableBtnCacherElement  = $('.datatable-btn-cacher-element');
    var datatableBtnMontrerElement  = $('.datatable-btn-montrer-element');

    // Bouton "Cacher les fichiers téléchargés"
    datatableBtnCacherElement.on('click', function(){
        datatableBtnMontrerElement.removeClass('hidden');
        datatableBtnCacherElement.addClass('hidden');
        hideAllDownloadedDatatables();
    });

    // Bouton "Montrer les fichiers téléchargés"
    datatableBtnMontrerElement.on('click', function(){
        datatableBtnCacherElement.removeClass('hidden');
        datatableBtnMontrerElement.addClass('hidden');
        showAllDownloadedDatatables();
    });

});



function toggleDatatable($this) {
    var idFormation = $this.attr('id');
    var idTable = "table-"+idFormation;
    $this.find("button .fa").toggleClass("fa-angle-down").toggleClass("fa-angle-right");
    if($("#table-"+idFormation+"_wrapper").length > 0) {
        $("#table-"+idFormation+"_wrapper").toggle();
    } else {
        $('#'+idTable).toggle();
        initDatatable(idTable);
    }
}

function initDatatable(idTable, columnForOrdering = 1) {
    if($('#'+idTable).length > 0){
        $.fn.dataTable.moment('DD/MM/YYYY');
        table = $('#'+idTable).DataTable({
            "language": {
                url: 'https://cdn.datatables.net/plug-ins/1.10.15/i18n/French.json'
            },
            "columnDefs": [
                { "orderable": false, "targets": 2 },
            ],
            "processing": true,
            "info": false,
            "order": [[ columnForOrdering, "desc" ]]
        });
        setStateDatatable($('#'+idTable));
    }
}

function setStateDatatable(htmlTable) {
    if ($('.datatable-btn-montrer-element.hidden').length > 0) {
        showDownloadedDatatables(htmlTable);
    } else {
        hideDownloadedDatatables(htmlTable);
    }
}

function hideDownloadedDatatables(htmlTable) {
    table = htmlTable.DataTable();
    $.fn.dataTable.ext.search.pop();
    $.fn.dataTable.ext.search.push(
        function(settings, data, dataIndex) {
            return !$(table.row(dataIndex).node()).hasClass('downloaded');
        }
    );
    table.draw();
}

function showDownloadedDatatables(htmlTable) {
    table = htmlTable.DataTable();
    $.fn.dataTable.ext.search.pop();
    table.draw();
}

function hideAllDownloadedDatatables() {
    $('.dataTables_wrapper .table-datatables').each(function(index, element) {
        hideDownloadedDatatables($('#'+ $(this).attr('id')));
    });
}

function showAllDownloadedDatatables() {
    $('.dataTables_wrapper  .table-datatables').each(function() {
        showDownloadedDatatables($('#'+ $(this).attr('id')));
    });
}
