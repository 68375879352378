$(document).ready(function() {
    $('.no-javascript').css('display','none'); // si il n'y a pas js activé


//******************** Vérification envoie message global ******************************/
    $("body").on('click', '.btn-envoyer-message', function(e) {


        var sujet = $('.sujet-message');
        var message = $('.envoyer-message');
        var messagePhone = $('.envoyer-message-phone');
        var textareaMessage = $('.textarea-message');
        var erreur = 0;

        if (sujet.val() === '') {
            erreur++;
            sujet.css('box-shadow','0 0 5px red');
            sujet.addClass('erreur-message');
            sujet.attr("placeholder", "Vous devez indiquer un sujet.");

        }  else{
            sujet.removeClass('erreur-message');
            sujet.css('box-shadow','none');
        }


        if (messagePhone.length > 0 && message.length > 0) {
            if(message.val() === '' && messagePhone.val() === ''){
                erreur++;
                textareaMessage.css('box-shadow','0 0 5px red');
                message.addClass('erreur-message');
                message.attr("placeholder", "Vous devez écrire un message.");
                messagePhone.addClass('erreur-message');
                messagePhone.attr("placeholder", "Vous devez écrire un message.");
            } else {
                textareaMessage.css('box-shadow','none');
                message.removeClass('erreur-message');
            }
        } else if (messagePhone.length < 1 && message.length > 0) {
            if(message.val() === '') {
                textareaMessage.css('box-shadow','0 0 5px red');
                message.addClass('erreur-message');
            } else {
                textareaMessage.css('box-shadow','none');
                message.removeClass('erreur-message');
            }
        }

        var nbFile = $('.piece-jointe-container').data('file');
        var extensionsValide = 'pdf,jpg,jpeg,png,gif,odt,ods,odp,xls,xlsx,ppt,pptx,doc,docx,mp3';

        $('p.erreur').remove();

        for (var i = 0; i <= nbFile-1; i++) {


            var fileName = $('.file-message-'+i).val();


            if(typeof(fileName) !== "undefined"){
                var fileArray = fileName.split('.');
                var fileExtension = fileArray[fileArray.length-1];
                if(extensionsValide.indexOf(fileExtension)===-1) {
                    erreur++;
                    $('.file-message-'+i).css('box-shadow','0 0 5px red');
                    $('.container-piece-jointe-'+i).after('<p class="erreur">Le format est incorrect.</p>');
                }
                else{
                    $('.file-message-'+i).css('box-shadow','none');
                }
            }

        }
        $('.piece-jointe-container').removeData('file');

        if(erreur != 0){
            e.preventDefault();
        }
    });

//************************* Vérification de l'envoie du message côté prof + modo ****************************/

    $("body").on('click', '.envoyer-message-prof', function(e) {
        var eleve = $('.eleve-message');
        var selectDesinataire = $('#type-destinataire').val();

        var erreur = 0;

        if(selectDesinataire === 'E'){
            if(eleve.val() ===''){
                erreur++;
                eleve.css('box-shadow','0 0 5px red');
                eleve.addClass('erreur-message');
                eleve.attr("placeholder", "Vous devez indiquer un élève.");

            }
            else{
                eleve.removeClass('erreur-message');
                eleve.css('box-shadow','none');
            }
        }
        else
        {
            eleve.removeClass('erreur-message');
            eleve.css('box-shadow','none');
        }
        var nbFile = $('.piece-jointe-container').data('file');

        var extensionsValide = 'pdf,jpg,jpeg,png,gif,odt,ods,odp,xls,xlsx,ppt,pptx,doc,docx,mp3';
        $('p.erreur').remove();

        for (var i = 0; i <= nbFile-1; i++) {


            var fileName = $('input[name=piece-jointe-'+i+']').val();

            if(typeof(fileName) !== "undefined"){
                var fileArray = fileName.split('.');
                var fileExtension = fileArray[fileArray.length-1];
                if(extensionsValide.indexOf(fileExtension)===-1) {
                    erreur++;
                    $('input[name=piece-jointe-'+i+']').css('box-shadow','0 0 5px red');
                    $('input[name=piece-jointe-'+i+']').parent().after('<p class="erreur">Le format est incorrect.</p>');
                }
                else{
                    $('input[name=piece-jointe-'+i+']').css('box-shadow','none');
                }
            }

        }
        $('.piece-jointe-container').removeData('file');

        if(erreur != 0){
            e.preventDefault();
        }
    });

/************ systeme auto completion pour la recherche eleve ( non implenté ) *********/
        $('.autoCompletion-eleve').select2({
            ajax: {
                url: "../../message/recherche/eleve",
                dataType: 'json',
                delay: 250,
                data: function (params) {

                    return {
                        q: params.term // search term
                    };
                },
                processResults: function (data) {
                    return {
                        results: data
                    };
                },
                cache: true
            },
            minimumInputLength: 0,
            maximumSelectionLength: 7,
            placeholder: "Recherche",
            language: "fr"
        });


/**************** Recherche en ajax d'elève *****************************/
/*       $("body").on('click', '.btn-recherche-eleve', function(e) {

            var ValEleve =  $('.autocomplete-eleve').val();

            $.ajax({
                type: 'GET',
                url: '../../message/recherche/eleve/'+ValEleve,
                dataType: 'json',
                success : function(data){ // code_html contient le HTML renvoyé
                    data['text'];
                    $('input.autocomplete-eleve').val(data['text']);
                    $('input[name="destinataire"]').val(data['id']);
                },
                error : function(resultat, statut, erreur){

                },
                complete : function(resultat, statut){
                    $('i.spinner-load-formation').remove('i');
                }

            });

    });

*/
function GestionFichierListe(lastId){
    $('p.erreur').remove();
    if( $('input[name=piece-jointe-'+lastId+']').val() === '' )
    {
        event.preventDefault();

        $('input[name=piece-jointe-'+lastId+']').css('box-shadow','0 0 5px red');
        $('input[name=piece-jointe-'+lastId+']').parent().after('<p class="erreur">Vous devez remplir la pièce jointe pour en ajouter une nouvelle.</p>');

        return false;
    }
    else
    {
        $('input[name=piece-jointe-'+lastId+']').css('box-shadow','none');
    }
}



$('.modifier-message-interne').click(function(e){
    e.preventDefault();
    $('.form-textarea-editon').removeClass('hidden');
    $(this).parent().addClass('hidden');
    $('.form-textarea-editon .some-textarea').removeClass('some-textarea-hidden');
});

$('.annuler-edition-message').click(function(e){
    e.preventDefault();
    $('.container-box-message').removeClass('hidden');
    $(this).parent().addClass('hidden')
    $('.form-textarea-editon .some-textarea').addClass('some-textarea-hidden');

});





});

function initRechercherEleve (url, urlFormation){
        $('.eleve-container select').select2({
            ajax: {
                type: 'POST',
                url: url,
                dataType: 'json',
                delay: 500,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: function (params) {

                    return {
                        q: params.term // search term
                    };
                },
                processResults: function (data) {
                    return {
                        results: data
                        // results: data
                    };
                },
                cache: true
            },
            minimumInputLength: 0,
            placeholder: "Votre recherche",
            language: "fr"
        });
        $(".eleve-container .select2").addClass('form-control');
        $(".eleve-container .select2").css('minWidth', '200px');
        $(".eleve-container select").on('select2:close', function (evt) {
            var val = $(".eleve-container select").val();
            if(val != null){
                $('input[type=hidden][name=destinataire]').val(val);
                chargementFormation(urlFormation);
            }
        });
    }

function initRechercheProfesseur (url){
        $('.professeur-container select').select2({
            ajax: {
                type: 'POST',
                url: url,
                dataType: 'json',
                delay: 500,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: function (params) {
                    return {
                        searchProf: params.term // search term
                    };
                },
                processResults: function (data) {
                    return {
                        results: data
                    };
                },
                cache: true
            },
            templateResult: function (data, container) {
                if (data.isInterne) {
                    $(container).addClass('bg-grey-light-1-no-imp');
                }
                return data.text;
            },
            minimumInputLength: 0,
            placeholder: "Votre recherche de professeur",
            language: "fr"
        });
        $(".professeur-container .select2").addClass('form-control');
        $(".professeur-container .select2").css('minWidth', '200px');
        $(".professeur-container select").on('select2:close', function (evt) {
            var val = $(".professeur-container select").val();
            if(val != null){
                $('input[type=hidden][name=destinataire]').val(val);
            }
        });
    }

    function chargementFormation (url){
        $('.cours-container').css('display', 'none');
        $('.formation-container').css('display','none');
        $('#spinner').show();
        //
        $.ajax({
            type: 'POST',
            url: url,
            data: 'idEleve=' + $(".eleve-container select").val(),
            dataType: 'json',
            delay: 20,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        })
        .done(function (resultat) {
            $('#spinner').hide();
            $('.formation-container').css('display','inline-block');
            $('#selectFormation').html('');
            if(resultat.length == 1){
                $idForma = (value.idForma!= -1 ? (value.idForma  +' - ') : '');
                $('<option value="-2"></option>').html($idForma+ resultat[0].libForma).appendTo('#selectFormation');
            }
            else{
                $('<option value="-2" selected="selected" disabled="disabled"></option>').html('Sélectionnez une formation...').appendTo('#selectFormation');
                $.each(resultat, function (index, value) {
                    $idForma = (value.idForma!= -1 ? (value.idForma  +' - ') : '');
                    element = $('<option value="'+ value.idForma +'"></option>').html( $idForma + value.libForma).appendTo('#selectFormation');
                    if (index == 1) {
                        $(element).prop('selected', true);
                    }
                });
                chargerSupportReference(resultat);
            }
        });
    }

    function chargerSupportReference (resultat){
        var select2Cours = $('.select-series-select2');
            select2Cours.select2();

        $(select2Cours).empty();
        var formationSelect = document.getElementsByName('formation')[0];
        //
        $(formationSelect).on('change', function( e) {
            var result = $.grep(resultat, function(e){ return e.idForma == $(formationSelect).val(); });

            if (result != undefined && result.length > 0) {
                if (result[0].idForma != "-1") {
                    if (result.length == 1 && result[0].serieForma.length > 0 ) {
                        var data = [];
                        data.push({
                            'id': -1,
                            'text': 'Aucun'
                        });

                        result[0].serieForma.forEach(function(serie) {
                            var dataCoursRef = [];
                            serie.coursRefSerie.forEach(function(coursRef) {
                                dataCoursRef.push({
                                    'id':coursRef.refSupCoursRef,
                                    'text': coursRef.refSupCoursRef + ' - ' + coursRef.titreCoursRef
                                });
                            });
                            data.push({
                                'id': serie.idSerie,
                                'text': serie.libSerie,
                                'children': dataCoursRef
                            });
                        });
                        select2Cours.select2({
                            placeholder: 'Séléctionnez un support...',
                            data: data,
                        })
                        $(".select2").addClass('form-control').css({'display': 'block', 'width': 'auto'});
                        $('.cours-container').css('display', 'inline-block');

                    } else {
                        $('.cours-container').css('display', 'none');
                    }
                } else {
                    $('.cours-container').css('display', 'none');
                }
            } else {
                $('.cours-container').css('display', 'none');
            }

        });

       $(formationSelect).trigger('change');
    }
